<template>
  <div>
    <general-table
      ref="utilitiesTable"
      :api-url="APIURL"
      :add-type="addType"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :view-content="false"
      :export-table="false"
      :type="type"
      :columns="columns"
      :guard="guard"
      :search-box="false"
      :selectable="false"
      :viw-component="viwComponent"
    />

  </div>
</template>

<script>

import GeneralTable from '@/components/general-table/GeneralTable.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'utilities',
      addType: 'Add Utility',
      addComponentName: 'add-utility',
      editComponent: 'edit-utility',
      viwComponent: 'show-user',
      type: 'page',
      guard: 'utility',
      columns: [

        { key: 'id', sortable: true },
        { key: 'name' },
        { key: 'service_code' },
        { key: 'service_provider_code', label: 'service provider code' },
        { key: 'service_transaction_type', label: 'service transaction type' },
        { key: 'short_code', label: 'Short code' },
        { key: 'type', label: 'Type' },

        { key: 'actions' },
      ],

      loader: false,
      userForm: {},
      types: {},
      currentTime: '',
      role: '',
    }
  },
  setup() {
    const { formData, setFormData } = handleFormData()

    return {
      formData, setFormData,
    }
  },
  created() {

  },
  methods: {

    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },

  },
}
</script>

<style scoped>
.change-status {
  cursor: pointer;
}
</style>
